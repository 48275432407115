import React from 'react';
import Header from "../header";
import Footer from "../../components/common/footer";
import ThreePlWarehousing from "../../components/common/threeplwarehouse";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation, I18nextContext, Link} from "gatsby-plugin-react-i18next";

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
 const Product = () => {
  const { language } = React.useContext(I18nextContext)
  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/product/warehouse-management-software/"
      : GATSBY_LANDING_PAGE_URL +`/${language}/product/warehouse-management-software/`
      const canoncalUrl = buildCanonicalUrl();

      const buildHrefUrl = () =>
      language === "en"
        ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
        : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
    const hrefUrl = buildHrefUrl();

       const { t } = useTranslation()
    return (
        <div className="Layout">
            
            <Header/>
            <ThreePlWarehousing/>
            <Footer/>
            <Helmet>
            <meta charSet="utf-8" />
          <title>{t('Warehouse Management System - 3PL Solution & WMS Software')}</title>
          <Link rel="canonical" href={canoncalUrl} />
          <Link rel="alternate" href={hrefUrl} hreflang={language} />
          <meta name="description" content={t("Logipulse Warehouse Management System and 3PL solutions enable automatic processing of warehouse operations from start to end, producing invoices and transferring data to accounting and other related areas, reducing complexity. Purchase Logipulse WMS software.")} />
          <meta name="Keywords" content={t("3Pl solutions,warehouse management system,WMS software,warehouse management software")}/>
           </Helmet>
        </div>
    )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Product