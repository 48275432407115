import React, { Component } from "react"
import "../../styles/threeplwarehousing.scss"
import Photo from "../../components/Photo";
import { graphql } from "gatsby";
import { Trans, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";



class threeplwarehouse extends Component {
  static contextType = I18nextContext;
  constructor() {
    super()
    this.state = {
      showMe: true,
      Multicurrency: true,
    }
  }
  Schedule(e, curObj) {

    var status = false;
    if (document.getElementById('feature__' + curObj).classList.contains('active_color')) {
      document.getElementById('feature__' + curObj).classList.remove('active_color');
      document.getElementById('arrow__' + curObj).classList.remove('active_color');
      document.getElementById('feature__' + curObj).classList.add('inactive_color');
      document.getElementById('arrow__' + curObj).classList.add('inactive_color');
      status = false;
    } else {
      document.getElementById('feature__' + curObj).classList.add('active_color');
      document.getElementById('arrow__' + curObj).classList.add('active_color');
      document.getElementById('feature__' + curObj).classList.remove('inactive_color');
      document.getElementById('arrow__' + curObj).classList.remove('inactive_color');
      status = true;
    }

    var custObj = {};
    custObj[curObj] = status;
    this.setState(custObj);
  }
  render() {
    return (
      <div>
        <Helmet>
          <script
            dangerouslySetInnerHTML={{
              __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TK7M58Z');
        
            `,
            }}
          />

          <noscript
            dangerouslySetInnerHTML={{
              __html: `
        
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>   
            `,
            }}
          />
      
        </Helmet>
        <div className="banner-feature">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-imgs">
                  <div className="pricings_in_details main-details" lang={I18nextContext._currentValue.language}>
                    <Link to="/"><Trans>HOME</Trans></Link>/ <span className="featuress"><Link to="/product/"><Trans>PRODUCTS</Trans></Link> / </span>
                    <span className="warehouse"><Trans>3PL & WAREHOUSING</Trans></span>
                  </div>
                  <div className="sub_main_contents" lang={I18nextContext._currentValue.language}>
                    <Trans>Real-Time Visibility and Control</Trans>{" "}
                  </div>
                  <div className="threewarehouse_third_content" lang={I18nextContext._currentValue.language}>
                    <Trans>Monitor and regulate the flow of cargo in and out, despite the volume, with Logipulse 3PL and Warehousing.</Trans>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="three_pl_warehouse">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 full_row_alignment">
                <div>
                  <Photo
                    src="3PL - Warehouse Header.jpg"
                    className="dimention_image"
                    alt=""
                  />                  
                </div>
              
                <div className="threeplsub_headings_words1" lang={I18nextContext._currentValue.language}>
                <h1 className="heading-size"><Trans>Warehouse Management Software</Trans></h1>
                  <Trans>As more and more companies opt for same day delivery and other fast delivery options, now would be the best time for you to switch from a traditional warehouse system to a fully integrated logistics business. Logipulse 3PL and warehousing module includes all the options you need to process warehouse operations from start to finish, and automatically generate invoices and transfer data to accounting.</Trans>
                  <br />
                </div>

                <div className="threeplsub_headings_words3" lang={I18nextContext._currentValue.language}>
                  <Trans>Logipulse 3PL and warehousing make your warehousing space capacity transparent through its 2D warehouse view feature. Bins and racks can also be defined according to your requirement. Any kind of rack can be configured into the app. The 2D view display lets you know the total space, occupied space, free space, and a lot more. Shipments will be auto assigned to free space by the system. Logipulse PDA device and application, barcode scanning, inventory management, etc. are additional benefits to you that make the job within half time and half cost.</Trans>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="highlights_way" lang={I18nextContext._currentValue.language}>
          <div className="container">
            <div className="Hight_light_heads_content"><Trans>Highlights</Trans></div>
            <div className="row full_row_aligns">


              <div className="col-sm-6">
                <ul>
                  <li>
                    <div className="material" id="arrow__Multicurrency" onClick={e => this.Schedule(e, 'Multicurrency')} style={{ cursor: "pointer" }}>
                      {this.state.Multicurrency ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>

                    <h5
                      className="active_color"
                      id="feature__Multicurrency"
                      onClick={e => this.Schedule(e, 'Multicurrency')}

                    >
                      <Trans>Multicurrency</Trans>
                    </h5>
                    {this.state.Multicurrency ? (
                      <div className="subheadings">
                        <Trans>Logipulse’s multicurency feature allows easy switching between currencies and supports multiple location currencies as well. That is to say, the origin port and destination port transactions can be in their local currencies and if needed the payment can be in a third different currency from that of the origin or destination ports.</Trans>


                        <div className="know_more">
                          <b>
                            <Link to="/features/#multicurrency" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link>
                          </b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>

                  <li>


                    <div className="material" id="arrow__rack" onClick={e => this.Schedule(e, 'rack')} style={{ cursor: "pointer" }}>
                      {this.state.rack ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5 id="feature__rack" onClick={e => this.Schedule(e, 'rack')} >

                      <Trans>Multilingual Interface</Trans>
                    </h5>
                    {this.state.rack ? (
                      <div className="subheadings">
                        <Trans>It doesn't matter in which location you are executing the container handling processes.</Trans>
                        <Trans>The advantage of multiple languages allows you or the associated people to manage it without the barrier of difference in language.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#multilingual" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>



                  <li>
                    <div className="material" id="arrow__Automated" onClick={e => this.Schedule(e, 'Automated')} style={{ cursor: "pointer" }}>
                      {this.state.Automated ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Automated"
                      onClick={e => this.Schedule(e, 'Automated')}
                    >
                      <Trans>2D Warehouse View</Trans>
                    </h5>
                    {this.state.Automated ? (
                      <div className="subheadings">
                        <Trans>You can have a quick look at your warehouse anytime and know in detail about the capacity of the warehouse, racks and bins, space used, free space, and a lot more information about each item on the app. You can also define the bins and racks according to your requirement. Any kind of rack can be configured into the app depending on the warehouse space you get.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#2DView" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>



                  <li>
                    <div className="material" id="arrow__Document" onClick={e => this.Schedule(e, 'Document')} style={{ cursor: "pointer" }}>
                      {this.state.Document ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Document"
                      onClick={e => this.Schedule(e, 'Document')}
                    >
                      <Trans>Define Rack Structure</Trans>
                    </h5>
                    {this.state.Document ? (
                      <div className="subheadings">
                        <Trans>Warehouses are not set prior according to future advancements, but our app is adaptable to any structure you define. The easy customizable setup enables you to arrange the structure you desire.</Trans>

                        {/* <div className="know_more">
                          <b> <Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null}
                  </li>


                  <li>
                    <div className="material" id="arrow__Customerportal" onClick={e => this.Schedule(e, 'Customerportal')} style={{ cursor: "pointer" }}>
                      {this.state.Customerportal ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Customerportal"
                      onClick={e => this.Schedule(e, 'Customerportal')}
                    >
                      <Trans>Individual Bin to Whole Warehouse View</Trans>
                    </h5>
                    {this.state.Customerportal ? (
                      <div className="subheadings">
                        <Trans>Each item will have a unique code to identify and track so that customers can check the availability of their stocks in each bin as well as in the whole warehouse.</Trans>
                      </div>
                    ) : null}
                  </li>



                  <li>
                    <div className="material" id="arrow__binLocation" onClick={e => this.Schedule(e, 'binLocation')} style={{ cursor: "pointer" }}>
                      {this.state.binLocation ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__binLocation"
                      onClick={e => this.Schedule(e, 'binLocation')}
                    >
                      <Trans>Automated Location and Bin Allocation</Trans>
                    </h5>
                    {this.state.binLocation ? (
                      <div className="subheadings">
                        <Trans>Manual bin allocation may take forever to complete the warehouse tasks. Instead, in Logipulse, the automated bin and rack allocation instantly take out the free space and allocate the items in the place utilizing the artificial intelligence integrated with the software.</Trans>

                        <div className="know_more">
                          <b><Link to="/features/#PDAdevice" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>


                  <li>
                    <div className="material" id="arrow__Emailing" onClick={e => this.Schedule(e, 'Emailing')} style={{ cursor: "pointer" }}>
                      {this.state.Emailing ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Emailing"
                      onClick={e => this.Schedule(e, 'Emailing')}
                    >
                      <Trans>Warehouse Movement</Trans>
                    </h5>
                    {this.state.Emailing ? (
                      <div className="subheadings">
                        <Trans>Sometimes you may need more space for items or may have to shift the warehouse to a different location or to one with more space or due to any circumstance. The warehouse movement feature of Logipulse makes the operation easy and adaptable for you to move items from one warehouse to another.</Trans>
                      </div>
                    ) : null}
                  </li>

                  <li>
                    <div className="material" id="arrow__Customizable" onClick={e => this.Schedule(e, 'Customizable')} style={{ cursor: "pointer" }}>
                      {this.state.Customizable ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Customizable"
                      onClick={e => this.Schedule(e, 'Customizable')}
                    >
                      <Trans>Connection to Freight Forwarding</Trans>
                    </h5>
                    {this.state.Customizable ? (
                      <div className="subheadings">
                        <Trans>You can create Import or Export shipments directly from the warehouse. Issued items can be delivered directly from the warehouse. You can make delivery faster than you think. If you have inventory stocked in the warehouse, that can be transferred to freight forwarding in any mode, for instance, land, ocean, or air. Related invoicing, billing, and reports can be generated within the module. You get all the advantages of the freight forwarding module here as it is deeply integrated to function together.</Trans>


                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null}
                  </li>

                  <li>
                    <div className="material" id="arrow__Rates" onClick={e => this.Schedule(e, 'Rates')} style={{ cursor: "pointer" }}>
                      {this.state.Rates ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5 id="feature__Rates" onClick={e => this.Schedule(e, 'Rates')}>
                      <Trans>PDA Device and Application</Trans>
                    </h5>
                    {this.state.Rates ? (
                      <div className="subheadings">
                        <Trans>Our PDA device is a handheld device with the Logipulse application integrated with all the operation management tools specifically for your warehouse processes. The device is enabled to create issues, receipt, put away and picklist, bin allocations, etc. Proof of delivery can be signed on the device. Product barcode scanning (Stock keeping unit SKU) will be easier with the Logipulse PDA device. Every item inbound and outbound will be scanned making further actions trackable. The device comes with a flexible and most user-friendly interface, even a new employee can handle the device without any training and access the app through it instead of a computer system.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#PDAdevice" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>


                  <li>
                    <div className="material" id="arrow__Freight" onClick={e => this.Schedule(e, 'Freight')} style={{ cursor: "pointer" }}>
                      {this.state.Freight ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Freight"
                      onClick={e => this.Schedule(e, 'Freight')}
                    >
                      <Trans>Automated Documents Generation</Trans>
                    </h5>
                    {this.state.Freight ? (
                      <div className="subheadings">
                        <Trans>Logistics operations include a lot of documents and paperwork that until now was done manually. By automating all the documents issuing and receiving, half of the task is considered done. Warehouse receipt issuing, Put-away and Pick-list documents, Proof of Delivery, invoices, manifest slips/sheets, content lists, arrival acknowledgment forms/reports, damaged goods, returned goods, summary reports, import/export, delivery, Bill of Lading (BOL), etc. can all be generated easily in Logipulse.</Trans>
                        <br></br><br></br>
                        <Trans>Different versions of documents can also be produced. Documents can be shared between agencies and can be emailed to desired people. Editable templates are available for you to create documents instantly. There are numerous documents required from pickup to delivery, automating and customizing it according to your need will make the process professional, accurate and fast.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#AutomatedDocuments" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>


                  <li>
                    <div className="material" id="arrow__Excel" onClick={e => this.Schedule(e, 'Excel')} style={{ cursor: "pointer" }}>
                      {this.state.Excel ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5 id="feature__Excel" onClick={e => this.Schedule(e, 'Excel')}>
                      <Trans>Integrated State Updates</Trans>
                    </h5>
                    {this.state.Excel ? (
                      <div className="subheadings">
                        <Trans>Logipulse provides you with a dedicated and most intelligent state engine that can track all the entities in your logistics business. Every item state will be notified to the customer, whether it is in shipment state, loaded, warehouse picklist, put-away, bin allocation, or whether the item is damaged or broken, etc. Whenever a state is changed for an item or any operation, an alert will be generated to you, your employees, consignee, or any other required set of people as SMS and emails. If an item or shipment is in the warehouse for a longer term, alerts will be sent, and a climb-up message also will be alerted to an employee in the higher position if the first person has missed it. You will know whether a task assigned is started, partially completed, or completed.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#IntegratedState" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>
                </ul>
              </div>



              <div className="col-sm-6">
                <ul>
                  <li>
                    <div className="material" id="arrow__bin" onClick={e => this.Schedule(e, 'bin')} style={{ cursor: "pointer" }}>
                      {this.state.bin ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5 id="feature__bin" onClick={e => this.Schedule(e, 'bin')}>
                      <Trans>Barcode and Scanning</Trans>
                    </h5>
                    {this.state.bin ? (
                      <div className="subheadings">
                        <Trans>It is an opportunity for you to increase efficiency and reduce errors across receiving, picking, shipping, and inventory operations through the scanning and barcodes feature of Logipulse. Automated data collection improves competence and reduces the overall period of tasks. Barcode scanning makes the label reading at light speed without failures. As items and shipments are scanned during all the phases of an operation, the captured information will be shared in real-time with employees or customers or to any other integrated systems. Every bin and every item in the bin will have separate barcodes.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null}
                  </li>


                  <li>
                    <div className="material" id="arrow__Movement" onClick={e => this.Schedule(e, 'Movement')} style={{ cursor: "pointer" }}>
                      {this.state.Movement ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Movement"
                      onClick={e => this.Schedule(e, 'Movement')}
                    >
                      <Trans>User Portal and Tracking</Trans>
                    </h5>
                    {this.state.Movement ? (
                      <div className="subheadings">
                        <Trans>Your customers can log in and fetch every information useful for the warehousing including the shipments, reports, invoices, etc. Track every activity and shipment you want to know. Warehouse receipt requests can be made here for item issues. It works as an agency portal. Employees in the agency can use it with the benefits of all the features and get every data regarding an operation in your logistics activity including shipping, warehouse, inventory, accounts, etc.</Trans>

                        <div className="know_more">
                          <b><Link to="/features/#CustomerPortal" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>



                  <li>
                    <div className="material" id="arrow__pdaDevice" onClick={e => this.Schedule(e, 'pdaDevice')} style={{ cursor: "pointer" }}>
                      {this.state.pdaDevice ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__pdaDevice"
                      onClick={e => this.Schedule(e, 'pdaDevice')}
                    >
                      <Trans>Automatic Rates Calculation</Trans>
                    </h5>
                    {this.state.pdaDevice ? (
                      <div className="subheadings">
                        <Trans>The exclusive accounting finance module that is thoroughly integrated with all other modules including the finance module helps you to do an automatic rate calculation easily, it covers invoices, bills, assets, liability, settlements, tax calculation, multicurrency calculations, and more. It helps you calculate the daily warehouse charges automatically and instantly.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null}
                  </li>

                  <li>
                    <div className="material" id="arrow__alert" onClick={e => this.Schedule(e, 'alert')} style={{ cursor: "pointer" }}>
                      {this.state.alert ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5 id="feature__alert" onClick={e => this.Schedule(e, 'alert')}>
                      <Trans>Auto Invoicing</Trans>
                    </h5>
                    {this.state.alert ? (
                      <div className="subheadings">
                        <Trans>An auto invoice report will be generated for every operation in the warehouse. For the incoming and outgoing shipments, storage, inventory purchase, sale, etc. Your unique pre-defined matrices will integrate data without any manual error.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null}
                  </li>



                  <li>
                    <div className="material" id="arrow__Scanning" onClick={e => this.Schedule(e, 'Scanning')} style={{ cursor: "pointer" }}>
                      {this.state.Scanning ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Scanning"
                      onClick={e => this.Schedule(e, 'Scanning')}
                    >
                      <Trans>Customizable Reports</Trans>
                    </h5>
                    {this.state.Scanning ? (
                      <div className="subheadings">
                        <Trans>You can customize all reports in Logipulse whether it is in issuing or receiving or inventory. Customization is possible with a basic knowledge of HTML. Label, invoice, GRN, proforma invoice, commercial invoice, a stock report in excel, HS code report, transfer of ownership report, and many more reports are included for inventory, 3PL, and warehousing processes.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#customreport" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null}
                  </li>



                  <li>
                    <div className="material" id="arrow__User" onClick={e => this.Schedule(e, 'User')} style={{ cursor: "pointer" }}>
                      {this.state.User ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5 id="feature__User" onClick={e => this.Schedule(e, 'User')}>
                      <Trans>Item List Data Upload</Trans>
                    </h5>
                    {this.state.User ? (
                      <div className="subheadings">
                        <Trans>When you start a new warehouse, it would be difficult for you to upload the bulk list of each item, instead, Logipulse provides you a facility to upload item data to the master. Master of item data can be generated through UPLOAD.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null}
                  </li>

                  <li>
                    <div className="material" id="arrow__Upload" onClick={e => this.Schedule(e, 'Upload')} style={{ cursor: "pointer" }}>
                      {this.state.Upload ? (
                        <Photo src="arrow_circle_up_black_24dp.svg" alt="" />
                      ) : (
                        <Photo
                          src="arrow_circle_down_black_24dp (1).svg"
                          alt=""
                        />
                      )}
                    </div>
                    <h5
                      id="feature__Upload"
                      onClick={e => this.Schedule(e, 'Upload')}
                    >
                      <Trans>Direct Emailing and Attachments</Trans>
                    </h5>
                    {this.state.Upload ? (
                      <div className="subheadings">
                        <Trans>You can email employees or customers from the mailing service available within the app. Every report can be sent in any format you require, you simply attach it along with the mail. You can also send it in excel format so that your accounting and related operations are handled easily.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            className="forwardarrows" lang={I18nextContext._currentValue.language}
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null}
                  </li>


                  <li>
                    <div className="material" id="arrow__Export" onClick={e => this.Schedule(e, 'Export')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Export ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }
                    </div>
                    <h5 id="feature__Export" onClick={e => this.Schedule(e, 'Export')}>
                      <Trans>Excel Export</Trans>
                    </h5>
                    {this.state.Export ? (
                      <div className="subheadings">
                        <Trans>Reports can be exported easily to customers or other users in excel format regardless of their existing format.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Inventorys" onClick={e => this.Schedule(e, 'Inventorys')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Inventorys ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Inventorys" onClick={e => this.Schedule(e, 'Inventorys')}>
                      <Trans>Inventory</Trans>
                    </h5>
                    {this.state.Inventorys ? (
                      <div className="subheadings">
                        <Trans>In-house inventory and customers inventory management are enabled in the app with seamless integration into the warehousing. You can create an inventory list, purchase request (internal stock filling request facility from warehouse owner to supplier), a purchase order (details of the purchasing), purchase receipt, and automatic invoice and bill generation is also present. Reports, purchase return (raise a return of damaged goods received), stock issue (selling or shipping inventory items from the warehouse), issued history (full log of issued stocks), stock return (the issued item returned shows the returned stocks), etc. All the above-mentioned operations are integrated with the accounting module so that every financial activity will be automatically reflected in the accounts whether it is a sale or a return. Moreover, warehouse shipments and receipts can be created from the inventory section. All the reports can be uploaded in excel format. Barcode, scanning, and associated tracking are other advantageous features.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#InventoryModule" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>
                </ul>
              </div>


            </div>
          </div>
        </div>

        <div class="end_divis_warehouse">
          <div className="header_contents_sections text-right"></div>
          <div className="container">
            <div className="row">
              <div class="col-sm-12">
                <Photo
                  src="Bottom Image-11.svg"
                  class="innerimage"
                  alt=""
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(threeplwarehouse)
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
